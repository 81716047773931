import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import BaseBanner from 'components/banners/base-banner';

const ALT = 'Register for an account with Total Loyalty';

const RegisterBanner = () => {
  const data = useStaticQuery(graphql`
   query {
     lg: file(relativePath: { eq: "register/register_large_desktop.png" }) {
       childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, formats: [AUTO,WEBP], placeholder:TRACED_SVG)
      }
     }
     md: file(relativePath: { eq: "register/register_desktop.png" }) {
       childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, formats: [AUTO,WEBP], placeholder:TRACED_SVG)
      }
     }
     sm: file(relativePath: { eq: "register/register_desktop.png" }) {
       childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, formats: [AUTO,WEBP], placeholder:TRACED_SVG)
      }
     }
     xs: file(relativePath: { eq: "register/register_mobile.png" }) {
       childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, formats: [AUTO,WEBP], placeholder:TRACED_SVG)
      }
     }
   }
 `);

  return (<BaseBanner alt={ALT} data={data}/>);
};

export default RegisterBanner;
